import { useState, useEffect } from "react";
import { message } from "antd";
const useFetchCustomerData = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [page, setPage] = useState(0);
  const [hasMore, setHasMore] = useState(true);

  const fetchData = async (page) => {
    try {
      setLoading(true);
      setError(null);

      const logindata = JSON.parse(localStorage.getItem("logindata"));
      const apiToken = logindata?.accessToken;
      const currentList = JSON.parse(localStorage.getItem("currentlist"));
      const listid = currentList?.listid;

      if (!apiToken || !listid) {
        throw new Error("Missing API token or listid");
      }

      const response = await fetch(
        "https://usfarmdata.org/fastapi/contact_function/?operation=getbycustomerlistid",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${apiToken}`,
          },
          body: JSON.stringify({
            listid: listid,
            page: page,
          }),
        }
      );

      if (!response.ok) {
        throw new Error(`Failed to fetch items: ${response.statusText}`);
      }

      const result = await response.json();
      console.log(result, "API Response");

      if (Array.isArray(result.data)) {
        // Append the new data to the existing data
        setData((prevData) => [...prevData, ...result.data]);

        if (result.data.length < 100) {
          setHasMore(false);
        }
      } else {
        throw new Error("Unexpected API response format. Expected an array.");
      }
    } catch (err) {
      setError(err.detail || err.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData(page);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  const loadMore = () => {
    if (hasMore) {
      setPage((prevPage) => prevPage + 1);
      message.info("Loading more contacts...");
    }
  };

  return { data, loading, error, hasMore, loadMore };
};

export default useFetchCustomerData;
