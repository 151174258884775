import React, { useState, useEffect } from "react";
import { Container, Box } from "@lib/Layout";
import CustomTable from "@components/CustomTable/CustomTable";
import ContactHeader from "../ContactHeader/ContactHeader";
import ContactSubHeader from "../ContactSubHeader/ContactSubHeader";
import FieldContainer from "../FieldContainer/FieldContainer";
import TextBoxField from "@components/TextBoxField/TextBoxField";
import { Button, message } from "antd";
import useFetchCustomerData from "../../../hooks/ListCenterHook/useFetchCustomerData";
import updateCustomerApi from "../../../hooks/updateCustomerApi";
import columns from "./contactColumns";

const CustomerContactManagement = () => {
  const [isGridView, setIsGridView] = useState(true);
  const { data, loading, hasMore, loadMore } = useFetchCustomerData();
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isEditing, setIsEditing] = useState(false);
  const [formData, setFormData] = useState({});
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  useEffect(() => {
    const storedContact = JSON.parse(localStorage.getItem("currentContact"));
    if (storedContact?.contactid && data.length > 0) {
      const index = data.findIndex(
        (contact) => contact.contactid === storedContact.contactid
      );
      if (index !== -1) {
        setCurrentIndex(index);
      }
    }
  }, [data]);

  const currentContact = data[currentIndex] || {};
  const currentList = JSON.parse(localStorage.getItem("currentlist"));
  const listId = currentList?.listid;

  useEffect(() => {
    if (currentContact?.ProfileDetails) {
      setFormData({
        firstname: currentContact.ProfileDetails.firstname || "",
        lastname: currentContact.ProfileDetails.lastname || "",
        address: currentContact.ProfileDetails.address || "",
        city: currentContact.ProfileDetails.city || "",
        state: currentContact.ProfileDetails.state || "",
        zipcode: currentContact.ProfileDetails.zipcode || "",
        phone: currentContact.ProfileDetails.phone || "",
        companyname: currentContact.ProfileDetails.companyname || "",
        email: currentContact.ProfileDetails.email || "",
        signupdate: currentContact.ProfileDetails.signupdate || "",
        replogin: currentContact.ProfileDetails.replogin || "",
      });
      localStorage.setItem(
        "currentContact",
        JSON.stringify({
          contactid: currentContact.contactid,
          email: currentContact.ProfileDetails.email,
        })
      );
    }
  }, [currentContact]);

  const handleInputChange = (field, value) => {
    setIsEditing(true);
    setFormData((prev) => ({ ...prev, [field]: value }));
  };
  const updateLocalStorage = (contact) => {
    if (contact?.ProfileDetails?.email && contact?.contactid) {
      localStorage.setItem(
        "currentContact",
        JSON.stringify({
          email: contact.ProfileDetails.email,
          contactid: contact.contactid,
        })
      );
    }
  };

  const handleNext = () => {
    if (isEditing) {
      message.warning("Please save your changes before navigating.");
      return;
    }
    if (currentIndex < data.length - 1) {
      setCurrentIndex((prev) => {
        const newIndex = prev + 1;
        updateLocalStorage(data[newIndex]);
        return newIndex;
      });
    } else {
      message.info("You've reached the last contact.");
    }
  };

  const handlePrevious = () => {
    if (isEditing) {
      message.warning("Please save your changes before navigating.");
      return;
    }
    if (currentIndex > 0) {
      setCurrentIndex((prev) => {
        const newIndex = prev - 1;
        updateLocalStorage(data[newIndex]);
        return newIndex;
      });
    } else {
      message.info("You're already at the first contact.");
    }
  };

  const handleSave = async () => {
    if (!currentContact.contactid) {
      message.error("No contact selected for update.");
      return;
    }
    await updateCustomerApi(currentContact.contactid, listId, formData)
      .then((response) => {
        console.log("Update successful:", response);
        setIsEditing(false);
        window.location.reload(true);
      })
      .catch((error) => {
        console.error("Update failed:", error);
        message.error("Failed to update customer data.");
      });
  };

  const handleViewToggle = (isGrid) => {
    const view = isGrid ? "grid" : "list";
    localStorage.setItem("view", view);
    setIsGridView(isGrid);
  };
  const handleSelectChange = (selectedKeys) => {
    console.log(selectedKeys);
    setSelectedRowKeys(selectedKeys);
  };

  const handleDelete = (record) => {
    console.log("Delete", record);
  };
  const handleGetCheckboxProps = (record) => {
    return {
      contactid: record.contactid,
    };
  };
  const handleEdit = (record) => {
    localStorage.setItem(
      "currentContact",
      JSON.stringify({
        contactid: record.contactid,
        email: record.ProfileDetails?.email,
      })
    );
    setCurrentIndex(
      data.findIndex((item) => item.contactid === record.contactid)
    );
    setFormData(record);
    handleViewToggle(true);
    window.location.reload();
  };

  return (
    <Container className="py-3 px-4 gap-8" alignBox="column">
      <Box>
        <ContactHeader
          nextClick={handleNext}
          prewClick={handlePrevious}
          currentIndex={currentIndex + 1}
          totalCount={data.length}
          onToggle={handleViewToggle}
          handleLoadMore={loadMore}
          loadMoreLoading={loading}
          loadMoreText={loading ? "Loading..." : "loadMore"}
          isLoadMore={loading || !hasMore}
        />
      </Box>

      {isGridView ? (
        <>
          <Box>
            <ContactSubHeader
              title={
                (currentContact?.ProfileDetails?.firstname || "") +
                " " +
                (currentContact?.ProfileDetails?.lastname || "")
              }
              contactId={currentContact.contactid}
              companyName={
                currentContact.ProfileDetails?.companyname || "Not Available"
              }
              Phone={currentContact.ProfileDetails?.phone || "Not Available"}
              Email={currentContact.ProfileDetails?.email || "Not Available"}
              Adders={currentContact?.ProfileDetails?.address || ""}
              isloading={loading}
              isEditing={isEditing}
            />
          </Box>
          <Box>
            <FieldContainer title="Profile">
              <Container alignBox="row" wrap="wrap" className="gap-y-3 gap-x-8">
                {currentContact.ProfileDetails ? (
                  <>
                    <div>
                      <TextBoxField
                        label="First Name"
                        value={formData.firstname}
                        onChange={(e) =>
                          handleInputChange("firstname", e.target.value)
                        }
                      />
                    </div>
                    <div>
                      <TextBoxField
                        label="Last Name"
                        value={formData.lastname}
                        onChange={(e) =>
                          handleInputChange("lastname", e.target.value)
                        }
                      />
                    </div>
                    <div>
                      <TextBoxField
                        label="Company Name"
                        value={formData.companyname}
                        onChange={(e) =>
                          handleInputChange("companyname", e.target.value)
                        }
                      />
                    </div>
                    <div>
                      <TextBoxField
                        label="Phone"
                        value={formData.phone}
                        onChange={(e) =>
                          handleInputChange("phone", e.target.value)
                        }
                      />
                    </div>
                    <div>
                      <TextBoxField
                        label="Email"
                        value={formData.email}
                        onChange={(e) =>
                          handleInputChange("email", e.target.value)
                        }
                      />
                    </div>
                  </>
                ) : (
                  <p>No contact data available.</p>
                )}
              </Container>
            </FieldContainer>
          </Box>
          <Box className="flex justify-between">
            <Button onClick={handleSave} disabled={!isEditing}>
              Save
            </Button>
          </Box>
        </>
      ) : (
        <Box className="p-2" flexible={loading ? true : false}>
          <div>{data.length}</div>
          {loading ? (
            <div role="status">
              <svg
                aria-hidden="true"
                class="inline w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-green-500"
                viewBox="0 0 100 101"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                  fill="currentColor"
                />
                <path
                  d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                  fill="currentFill"
                />
              </svg>
              <span class="sr-only">Loading...</span>
            </div>
          ) : (
            <CustomTable
              columns={columns}
              data={data}
              rowKey="contactid"
              onEdit={handleEdit}
              onSelectChange={handleSelectChange}
              onDelete={handleDelete}
              getCheckboxProps={handleGetCheckboxProps}
              selectableRows={true}
              selectedRowKeys={selectedRowKeys}
              EmptyMessage={() => <div>No Data Available</div>}
            />
          )}
        </Box>
      )}
    </Container>
  );
};

export default CustomerContactManagement;
