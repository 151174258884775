import React from "react";
import { useNavigate } from "react-router-dom";
import { Container, Box } from "@lib/Layout";
import Tooltip from "@mui/material/Tooltip";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import Title from "../common/Title/Title";

import { Button } from "antd";
import FilterListIcon from "@mui/icons-material/FilterList";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import ViewToggleButton from "../common/ViewToggleButton/ViewToggleButton";
import { useWindowWidth } from "../../../context/WindowContext";

const ContactHeader = ({
  handleFilter,
  onToggle,
  listName = "List Centers",
  nextClick,
  prewClick,
  currentIndex,
  totalCount,
  needActions = true,
  handleLoadMore,
  loadMoreLoading,
  loadMoreText,
  isLoadMore,
}) => {
  const windowWidth = useWindowWidth();
  const currentListData = localStorage.getItem("currentlist");
  const currentList = currentListData ? JSON.parse(currentListData) : {};
  const sharedListFlag = currentList.sharedlistflag === true;
  const Listname = currentList?.listname;
  const navigate = useNavigate();

  const handleNavigation = () => {
    navigate("/listcenter");
  };
  return (
    <Container
      alignBox={windowWidth > 600 ? "row" : "column"}
      align={windowWidth > 600 && "vertical"}
    >
      <Box flexible>
        <div className="flex flex-row items-center gap-4">
          <Title
            tagName="h1"
            className="font-segoe text-lg font-semibold antialiased"
            style={{ color: "#3A3A3A" }}
          >
            Contact Details
          </Title>
          <div>
            <div className="flex flex-row items-center gap-1 ">
              <span
                className="text-sm text-gray-500 cursor-pointer"
                onClick={handleNavigation}
              >
                {listName}
              </span>
              <span className="text-gray-400">
                <KeyboardArrowRightIcon />
              </span>
              <span className="text-sm text-blue-400 font-semibold">
                {Listname}
                {sharedListFlag && (
                  <span class="bg-green-100 ml-1 text-green-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded  border border-green-400">
                    Shared
                  </span>
                )}
              </span>
            </div>
          </div>
        </div>
      </Box>
      <Box>
        <div
          className="flex flex-row-reverse items-center gap-4"
          style={{ flexDirection: windowWidth > 600 ? "row-reverse" : "row" }}
        >
          <div>
            <ViewToggleButton onToggle={onToggle} />
          </div>
          {needActions && (
            <>
              <div>
                <div className="flex flex-row items-center gap-1 font-segoe font-semibold text-gray-400">
                  <span>{currentIndex}</span> <span>of</span>
                  <span>{totalCount}</span>
                </div>
              </div>
              <div>
                <div className="flex flex-row items-center gap-3">
                  <Button
                    color="light"
                    style={{ padding: "0.25rem" }}
                    onClick={prewClick}
                    disabled={currentIndex === 1}
                  >
                    <Tooltip title="Preview">
                      <KeyboardArrowLeftIcon />
                    </Tooltip>
                  </Button>
                  <Button
                    color="light"
                    style={{ padding: "0.25rem" }}
                    onClick={nextClick}
                    disabled={currentIndex >= totalCount - 0}
                  >
                    <Tooltip title="Next">
                      <KeyboardArrowRightIcon />
                    </Tooltip>
                  </Button>
                </div>
              </div>
            </>
          )}

          <div>
            <Button onClick={handleFilter} type="primary">
              <div className="flex flex-row items-center gap-1">
                <FilterListIcon />
                <span>Filter</span>
              </div>
            </Button>
          </div>
          {loadMoreText && (
            <div>
              <Button
                type="primary"
                ghost
                onClick={handleLoadMore}
                disabled={isLoadMore}
                loading={loadMoreLoading}
              >
                {loadMoreText}
              </Button>
            </div>
          )}
        </div>
      </Box>
    </Container>
  );
};

export default ContactHeader;
