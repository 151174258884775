const grayNA = <span className="text-gray-400">N/A</span>;

const columns = [
  {
    title: "Name",
    dataIndex: "name",
    key: "name",
    render: (_, record) =>
      record.ProfileDetails?.firstname || record.ProfileDetails?.lastname
        ? `${record.ProfileDetails?.firstname || ""} ${
            record.ProfileDetails?.lastname || ""
          }`
        : grayNA,
  },
  {
    title: "Email",
    dataIndex: "email",
    key: "email",
    render: (_, record) => record.ProfileDetails?.email || grayNA,
  },
  {
    title: "State",
    dataIndex: "state",
    key: "state",
    render: (_, record) => record.ProfileDetails?.state || grayNA,
  },
  {
    title: "Company",
    dataIndex: "company",
    key: "company",
    render: (_, record) => record.ProfileDetails?.companyname || grayNA,
  },
  {
    title: "Phone",
    dataIndex: "phone",
    key: "phone",
    render: (_, record) => record.ProfileDetails?.phone || grayNA,
  },
];

export default columns;
