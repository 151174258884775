import { message } from "antd";

const updateCustomerApi = async (contactId, listId, profileDetails) => {
  const logindata = JSON.parse(localStorage.getItem("logindata"));
  const apiToken = logindata?.accessToken;

  if (!apiToken || !contactId || !listId) {
    message.error("Missing API token, contactId, or listId");
    throw new Error("Missing API token, contactId, or listId");
  }

  if (typeof profileDetails !== "object" || profileDetails === null) {
    message.error("Invalid profile details format. Expected an object.");
    throw new Error("Invalid profile details format. Expected an object.");
  }

  try {
    const requestBody = {
      listid: listId,
      contactid: contactId,
      ProfileDetails: profileDetails,
    };

    console.log("Request Payload:", JSON.stringify(requestBody, null, 2));

    const response = await fetch(
      "https://usfarmdata.org/fastapi/contact_function/?operation=updatecustomercontact",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${apiToken}`,
        },
        body: JSON.stringify(requestBody),
      }
    );

    console.log("Response Status:", response.status);

    let responseData;
    try {
      responseData = await response.json();
      console.log("Response Data:", responseData);
    } catch (parseError) {
      console.error("Failed to parse response as JSON:", parseError);
      message.error("Failed to parse server response.");
      responseData = null;
    }

    if (!response.ok) {
      const errorMessage = responseData?.detail || "An error occurred";

      if (response.status === 400) {
        message.error(`Bad Request: ${errorMessage}`);
        throw new Error(`Bad Request: ${errorMessage}`);
      } else if (response.status === 500) {
        message.error(`Server Error: ${errorMessage}`);
        throw new Error(`Server Error: ${errorMessage}`);
      } else {
        message.error(`Error: ${errorMessage}`);
        throw new Error(errorMessage);
      }
    }

    message.success("Customer updated successfully!");
    return responseData;
  } catch (error) {
    console.error("Error in updateCustomerApi:", error);
    message.error(error.message || "An unexpected error occurred.");
    throw error;
  }
};

export default updateCustomerApi;
