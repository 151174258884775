import axios from "axios";
import React from "react";
import { Tooltip } from "antd";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import DeleteIcon from "@mui/icons-material/Delete";
import CancelScheduleSendIcon from "@mui/icons-material/CancelScheduleSend";
import ContactMailIcon from "@mui/icons-material/ContactMail";
import CampaignIcon from "@mui/icons-material/Campaign";
//import PrimarySharedList from "../components/PrimarySharedList"; // Adjust the path as necessary
//mport SecondarySharedList from "../../components/"; // Adjust the path as necessary

const PrimarySharedList = () => {
  return (
    <div className="flex items-center gap-1">
      <span class="bg-green-100 text-green-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded  border border-green-400">
        Shared
      </span>
    </div>
  );
};

const SecondarySharedList = () => {
  return (
    <div className="flex items-center gap-1">
      <div className="rounded-full h-5 w-5 bg-red-600 text-white">
        <Tooltip title="Shared List">
          <span className="flex items-center justify-center text-sm">S</span>
        </Tooltip>
      </div>
    </div>
  );
};

export const fetchCustomerListData = async (
  setLoading,
  setapiTblData,
  showUSLModal,
  showDLModal,
  ViewCustomerNavigate,
  Viewlistactions
) => {
  try {
    setLoading(true);
    const logindata = JSON.parse(localStorage.getItem("logindata"));
    const apiuserID = logindata?.userId;
    const apiToken = logindata?.accessToken;

    const response = await axios.post(
      "https://usfarmdata.org/fastapi/list_function/?operation=getcustomerlist",
      {
        userid: apiuserID,
        listname: "",
        listcount: "",
        listtype: "",
        listdatefrom: "",
        listdateto: "",
        shareduserid: "",
        mergedlistflag: "",
      },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${apiToken}`,
        },
      }
    );

    const data = response.data;
    console.log("Database list:", data);

    const formattedData = data.map((item) => {
      let sharedContent = "";
      let cancelListshare = "";
      if (item.sharedlistflag) {
        if (apiuserID === item.userid) {
          sharedContent = <PrimarySharedList />;
          cancelListshare = (
            <Tooltip title="Unshare the list">
              <CancelScheduleSendIcon
                className="tblunshareicon"
                fontSize="small"
                onClick={() => showUSLModal(item.listid)}
              />
            </Tooltip>
          );
        } else {
          sharedContent = <SecondarySharedList />;
        }
      }

      return {
        key: item.listid,
        userid: item.userid,
        isshared: item.sharedlistflag ? 1 : 0,
        ismerged: item.mergedlistflag ? 1 : 0,
        shared: sharedContent,
        listcount: item.listcount,
        shareduserids: item.shareduserids,
        listname: (
          <a
            href="#"
            onClick={(e) => {
              e.preventDefault(); // Prevent the default anchor behavior
              ViewCustomerNavigate(item);
            }}
            className="text-blue-500 block"
          >
            <Tooltip title="Click to view contact list details">{`${item.listname} (${item.listcount})`}</Tooltip>
          </a>
        ),
        viewlist: (
          <Tooltip title="View this actions">
            <RemoveRedEyeIcon
              className="tblviewicon"
              style={{
                cursor:
                  item.sharedlistflag === false ? "not-allowed" : "pointer",
                opacity: item.sharedlistflag === false ? 0.5 : 1,
              }}
              onClick={() =>
                item.sharedlistflag !== false && Viewlistactions(item)
              }
              fontSize="small"
              // onClick={() => Viewlistactions(item)}
            />
          </Tooltip>
        ),

        listdate: new Date(item.listdate).toLocaleDateString("en-US", {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
        }),

        removelist:
          apiuserID === item.userid ? (
            <Tooltip title="Delete this list">
              <DeleteIcon
                className="tbldeleteicon"
                fontSize="small"
                onClick={() => showDLModal(item.listid)}
              />
            </Tooltip>
          ) : (
            ""
          ),
        cancelshare: cancelListshare,
        // deploypostcard: (
        //   <ContactMailIcon className="tbldeployicon" fontSize="small" />
        // ),
        // campaign: <CampaignIcon className="tblcampaignicon" fontSize="small" />,
      };
    });

    setapiTblData(formattedData);
  } catch (error) {
    console.error("Error fetching list data:", error);
    setapiTblData(null);
  } finally {
    setLoading(false);
  }
};
